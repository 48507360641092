header {
    background-color: #333;
    padding: 1rem;
  }
  
  nav ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    justify-content: space-around;
  }
  
  nav ul li {
    margin: 0 10px;
  }
  
  nav ul li a {
    color: white;
    text-decoration: none;
  }
  
  nav ul li a:hover {
    text-decoration: underline;
  }
  